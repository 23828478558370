#DetailedInfo {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    top:0%;
    left:0%;
}

#DetailedPanel {
    position: absolute;;
    width: 50%;
    background-color: #FFFFFF;
    border-radius: 20px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#DetailedPanel table{
    width: 100%;
    height: 100%;
    border-spacing: 0px;
}

#DetailedItemInfo {
    width: 50%;
    padding: 20px;
}

#DetailedItemInfo table {
    table-layout: fixed;
}

#DetailedItemInfo td {
    padding: 2% 0;
}

#DetailedItemInfo input {
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #FFFFFF;
    border: 2px solid #000000;
}

#DeleteBtn {
    background-color: #C60808 !important;
}

#DeleteBtn:hover {
    background-color: #C42E2E !important;
}

#DeleteBtn:active {
    background-color: #C60808 !important;
}

#DetailedItemInfo button {
    width: 80%;
    height: 100%;
    border-radius: 5px;
    background-color: #FFFFFF;
    display: block;
    margin:auto;
    border: 2px solid #000000;
}

#DetailedItemInfo button:hover {
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
}

#DetailedItemInfo button:active {
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2);
}

#DetailedItemImage {
    width: 50%;
    padding: 20px;
    position: relative;
}

#ClearImageBtn {
    bottom: 3%;
    background-color: #39C9CF;
    width: 50%;
    height: 30px;
    border-radius: 5px;
    display: block;
    margin: auto;
}

#ClearImageBtn:hover {
    background-color: #5CE0E6;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
}

#ClearImageBtn:active {
    background-color: #39C9CF;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2);
}
