* {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
}
