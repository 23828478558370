.FileBox {
    width: 100%;
    height: 90%;
    position: relative;
    transform: translate(0%, -5%);
}

.FileInput {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 5px dashed #000000;
    background-color: #FFFFFF;
    position: relative;
}


.FileImage {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.UploadInput {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.dragging {
    background-color: #39C9CF;
}

.dragging input {
    visibility: hidden;
}
