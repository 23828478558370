.Remarks {
    position: absolute;
    width: 20%;
    height: 60%;
    padding: 5px;
    background-color: #FFFFFF;
    top: 20%;
    right: 2.5%;
    border-radius: 20px;
    overflow: scroll;
    border: 2px solid #000000;
}

.Remarks h1 {
    text-align: center;
}

.Remarks::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
}

.RemarkText {
    width: 100%;
    word-break: break-all;
    white-space: pre-wrap;
}

.RemarkTextarea {
    width: 100%;
    height: 100%;
}

.RemarkTextarea textarea {
    width: 100%;
    height: 90%;
    resize: none;
    box-sizing: border-box;
    background-color: #FFFFFF;
    font-size: 20px;
}

.RemarkTextarea::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
}
