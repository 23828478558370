#LoginPage {
    width: 100vw;
    height:100vh;
    display: grid;
    grid-template-rows: 1fr 2fr 1fr;
}

.PanelCol {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
}

.LoginPanel {
    background-color: #FFFFFF;
    padding: 20px 50px 20px;
    margin: auto 0;
    border-radius: 50px;
    border: 2px solid #000000;
    display: grid;
    grid-template-rows: auto auto minmax(50px, 1fr) 1fr;
}

#loginTitle {
    text-align: center;
    font-family: monospace;
    font-size: 40px;
}

.inputRow {
    padding: 0 0 20px 0;
}

.loginInputLabel {
    font-family: monospace;
    font-size: 15px;
    margin: auto 0;
}

.loginInputBox {
    width: 100%;
    font-size: 20px;
    padding: 10px 10px;
    margin: 0 0 10px 0;
    border-radius: 5px;
    background-color: #a3cbe6;
}

*:focus {
    outline: none !important;
}

.loginButton {
    margin: auto;
    width: 40%;
    height: 40px;
    font-size: 20px;
    background-color: #8cc3e8;
    border-radius: 10px;
    box-shadow: none;
}

.loginButton:hover {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #8cc3e8;
}

.loginButton:active {
    box-shadow: 0 0 0 2px white, 0 0 0 4px #49a6e4;
}
