.ItemContainer {
    width: 500px;
    height: 300px;
    margin: 10px;
    padding: 10px;
    display: inline-block;
    border-radius: 20px;
    border: 2px solid #000000;
}

.ItemContainer table {
    width: 100%;
    height: 100%;
    border-spacing: 0px;
}

.ItemInfo {
    width: 50%;
    padding: 5px;
}

.ItemThumbnail {
    width: 50%;
    padding: 5px;
}

.ItemThumbnail img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.AddItem {
    text-align: center;
    font-size: 200px;
    line-height: 100%;
    user-select: none;
}

.FakeImage {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
