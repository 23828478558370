#InventoryContainer {
    padding: 10px;
    width: 100vw;
    height: 500px;
    background-clip: content-box; /* optional */
}

#returnButton {
    width: 200px;
    height: 70px;
    background-color: #FFFFFF;
    margin: 10px;
    border-radius: 25px;
    font-size: 25px;
    border: 2px solid #000000;
}

#returnButton:hover {
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
}

#returnButton:active {
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2);
}
