.StockRecord {
    position: absolute;
    width: 20%;
    height: 80%;
    top: 10%;
    left: 2.5%;
}

.StockRecord table {
    width: 100%;
    height: 100%;
    border-spacing: 0px 5px;
}

.RecordContainer {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    overflow: scroll;
}
.RecordContainer::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
}
.RecordContainer table {
    height: auto;
    text-align: center;
    padding: 5px;
    table-layout: fixed;
}

.RecordContainer th {
    text-align: left;
    font-size: 25px;
    padding-left: 10px;
}

.In {
    background-color: #FFFFFF;
}

.Out {
    background-color: #FFFFFF;
}

.Record td {
    border: 1px solid black;
}

.Record input {
    width: 90%;
    text-align: center;
}

.SmallBtn {
    display: inline-block;
    background-color: #34B009;
    height: 15px;
    line-height: 15px;
    font-size: 15px;
    width: 15px;
    border-radius: 7.5px;
}

.SmallBtn span{
    text-align: center;
    vertical-align: middle;
}
