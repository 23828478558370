#CategorySelectContainer {
    position: absolute;
    left: 50%;
    top:50%;
    border-radius: 20px;
    transform: translate(-50%, -50%);
    padding: 30px;
    border: 2px solid #000000;
}
#CategorySelectContainer h1 {
    text-align: center;
    padding-bottom: 1em;
}
.CategoryButton {
    width: 200px;
    height: 2.5em;
    border-radius: 0.5em;
    font-size: 20px;
    margin: 0px 20px;
    background-color: #4ED1E8;
}

.CategoryButton:hover {
    background-color: #67DBF0;
    box-shadow: 0 8px 15px 0 rgba(0, 0, 0, 0.3);
}

.CategoryButton:active {
    background-color: #4ED1E8;
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.2);
}
